import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Container } from '../components/Layout/GlobalStyles';
import { withTrans } from '../i18n/withTrans';
// export default () => {
//   useEffect(() => {
// 		navigate('http://localhost:8000/lv');
// 	}, []);
// 	return null;
// };

const NotFoundPage = ({ t }) => {
	<Container>
		<StaticImage src="../images/PageNotFound.svg" alt="Page not found" />
		<h1>{t('notFound.heading')}</h1>
		<Link to="/">{t('notFound.link')}</Link>
	</Container>;
};

export default withTrans(NotFoundPage);